import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Box, Heading, Text } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import renderAst from '../components/ast-components'
import FunkyBorder from '../components/funky-border'
import ImageFrag from '../components/image-frag'
import ProductListing from '../components/product-listing'
import HorizontalRule from '../components/horizontal-rule'
import NewsletterSection from '../components/newsletter-section'
import SocialBanner from '../components/social-banner'
import { articleStructuredData } from '../components/meta/structured-data'

const Article = ({
  data: { page, suggestions },
  pageContext: { breadcrumb, blogHandle },
}) => {
  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo title={page.seo.title} description={page.seo.description} />
      <Helmet>
        <script type="application/ld+json">
          {articleStructuredData({
            headline: page.seo.title,
            URL: 'https://mudfoods.com/' + blogHandle + '/' + page.handle,
            imageUrl: page.image.src,
            datePublished: page.publishedAt,
            authorName: page.author.name,
          })}
        </script>
      </Helmet>
      <Container py={10}>
        <Box mb={6}>
          <Box sx={{ float: 'right' }} maxW="400px" ml={2}>
            <FunkyBorder white="true" thickness="4" rotate="3">
              <ImageFrag imageData={page.image} white="true" thickness="4" />
            </FunkyBorder>
          </Box>
          <Heading as="h1" mb={2}>
            {page.title}
          </Heading>
          {renderAst(page.childHtmlRehype.htmlAst)}
        </Box>

        {suggestions && suggestions.nodes.length > 0 && (
          <>
            <HorizontalRule />
            <Box my={[10]}>
              <Text mb={4} fontSize="3xl">
                Don't miss these!
              </Text>
              <ProductListing products={suggestions.nodes} maximum={4} />
            </Box>
          </>
        )}

        {blogHandle != 'events' && <NewsletterSection mt={2} />}
        <SocialBanner mt={6} />
      </Container>
    </Layout>
  )
}

export default Article

export const articleQuery = graphql`
  query articleQuery($id: String!, $tags: [String!]) {
    page: shopifyArticle(id: { eq: $id }) {
      handle
      author {
        name
      }
      childHtmlRehype {
        html
        htmlAst
      }
      title
      seo {
        title
        description
      }
      content
      contentHtml
      excerpt
      excerptHtml
      image {
        src
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              transformOptions: { cropFocus: ATTENTION }
            )
          }
        }
      }
      publishedAt
      tags
    }
    suggestions: allShopifyProduct(filter: { tags: { in: $tags } }) {
      nodes {
        ...ProductCard
      }
    }
  }
`
