import React, { useState, useEffect } from 'react'
import { Grid, useMediaQuery, Fade } from '@chakra-ui/react'
import ProductCard from './product-card'
import Filter from './filter'

const ProductListing = ({
  products,
  filter,
  childPositionLast,
  children,
  maximum = 99,
  offset = 1,
  bottomlines = [],
}) => {
  let productList = []
  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
  const [activeProducts, setActiveProducts] = useState(productList)

  if (products) {
    products.forEach((product) => {
      if (product.variants[0].availableForSale) productList.push(product)
    })
  }

  const [isMaxWidthMedium, setIsMaxWidthMedium] = useState(false)

  useEffect(() => {
    if (isSmallerThan1024 !== isMaxWidthMedium) {
      setIsMaxWidthMedium(isSmallerThan1024)
    }
  }, [isSmallerThan1024, isMaxWidthMedium])

  function getBottomLine(id) {
    const gid = id.substring('gid://shopify/Product/'.length)
    const bottomLine =
      bottomlines && bottomlines.length > 0
        ? bottomlines.find((o) => o.productIdentifier === gid)
        : null
    // bottomLine != null && console.log('bottomLineFound: ' + bottomLine.score)
    return bottomLine
  }

  return (
    <>
      {filter && (
        <Filter
          productList={productList}
          setActiveProducts={setActiveProducts}
        />
      )}

      <Grid
        templateColumns={[
          '1fr',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
        ]}
        columnGap={[2, 3, 4, 4]}
        rowGap={[0]}
      >
        {childPositionLast && children}
        {activeProducts.length > 0
          ? activeProducts.slice(offset - 1, maximum + offset - 1).map(
              (p, index) =>
                p != null && (
                  <>
                    <ProductCard
                      product={p}
                      key={`${p.title} + active + ${index}`}
                      bottomline={getBottomLine(p.shopifyId)}
                    />
                    {!childPositionLast &&
                      index === (isMaxWidthMedium ? 3 : 2) &&
                      children}
                  </>
                )
            )
          : productList.slice(offset - 1, maximum + offset - 1).map(
              (p, index) =>
                p != null && (
                  <Fade in={'true'}>
                    {console.log(p.variants[0].availableForSale)}
                    <ProductCard
                      product={p}
                      key={`${p.title} + disabled`}
                      bottomline={getBottomLine(p.shopifyId)}
                    />
                    {!childPositionLast &&
                      index === (isMaxWidthMedium ? 3 : 2) &&
                      children}
                  </Fade>
                )
            )}
      </Grid>
    </>
  )
}

export default ProductListing
